import config from '../../config'

let Analytics = {
  prepareAnlayze: function () {
    if (!config.isProduction) {
      return
    }
    // umeng
    const script = document.createElement('script')
    script.src = 'https://s96.cnzz.com/z_stat.php?id=1275226807&web_id=1275226807'
    script.language = 'JavaScript'
    document.body.appendChild(script)

    // bd
    window._hmt = window._hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?260a18797782e81b5b2cb047a9b50149";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();

    // GA
    window.dataLayer = window.dataLayer || [];
    const gscript = document.createElement('script')
    gscript.src = 'https://www.googletagmanager.com/gtag/js?id=UA-175939842-1'
    gscript.language = 'JavaScript'
    document.body.appendChild(gscript)
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'UA-175939842-1');
    gtag('config', 'UA-175939842-1', {
      'custom_map': {'dimension1': 'DeviceType'}
    });
    gtag('event', 'device_type_dimension', {'DeviceType': Analytics.getDeviceTypeForGA()});
  },
  recordVisit: function (referer) {
    if (!config.isProduction) {
      return
    }
    // umeng
    const content_url = `${location.pathname}${location.search}${location.hash}`
    const referer_url = referer
    const channels = Analytics.extractChannels()
    if (window._czc) {
      channels.forEach((item) => {
        _czc.push(['_setCustomVar', item.name, item.value, 2])
      })
      _czc.push(['_trackPageview', content_url, referer_url])
    }

    // bd
    if (window._hmt) {
      channels.forEach((item) => {
        window._hmt.push(['_setCustomVar', 1, item.name, item.value, 2])
      })
      window._hmt.push(['_trackPageview', content_url]);
    }

    // GA
    if (window.dataLayer) {
      function gtag(){ window.dataLayer.push(arguments); }
      gtag('config', 'UA-175939842-1', { 'page_path': content_url, 'page_title': document.title });
      gtag('config', 'UA-175939842-1', {
        'custom_map': {'dimension1': 'DeviceType'}
      });
      gtag('event', 'device_type_dimension', {'DeviceType': Analytics.getDeviceTypeForGA()});
    }
  },
  extractChannels: function () {
    const urlParams = new URLSearchParams(window.location.search);
    const channels = []
    const keys = ['from', 'ref']
    keys.forEach((key) => {
      var value = urlParams.get(key);
      if (value) {
        channels.push({
          name: key,
          value
        })
      }
    })
    return channels
  },
  getOS: function () {
    var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
      } else if (/Android/.test(userAgent)) {
      os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
    return os;
  },
  isSafari: function () {
    var ua = navigator.userAgent;
    if (
    /Safari/.test(ua) &&
    !/Chrome/.test(ua) &&
    !/Android/.test(ua) &&
    !/MicroMessenger/i.test(ua) &&
    !ua.match(/CriOS/i) &&
    !ua.match(/OPiOS/i)
    ) {
      return true;
      } else {
      return false;
    }
  },
  isPWA: function () {
    var mqStandAlone = '(display-mode: standalone)';
    return navigator.standalone || window.matchMedia(mqStandAlone).matches;
  },
  getDeviceTypeForGA: function () {
    var deviceType;
    var os = Analytics.getOS();
    if (Analytics.isPWA()) {
      deviceType = os + ' PWA';
    } else if (/MicroMessenger/i.test(navigator.userAgent)) {
      deviceType = os + ' Wechat';
    } else if (os == 'iOS' && Analytics.isSafari()) {
      deviceType = os + ' Safari';
    } else {
      deviceType = 'Others';
    }
    return deviceType;
  }
}
export default Analytics;