import axios from "axios"
import storage from '../js/storage.js';

var OauthToken = {
  get () {
    return {
      refresh_token: storage.get('refresh_token'),
      access_token: storage.get('access_token')
    };
  },
  update (accessToken, refreshToken) {
    if (accessToken && refreshToken) {
      storage.set('access_token', accessToken);
      storage.set('refresh_token', refreshToken);
      storage.set('oauth_token_updated_at', +(new Date));
    }
  },

  // 尝试让 access token 不过期，如果 token 已经失效，比如超过 7 天，就没办法了
  // 返回 promise 对象
  try_update_token () {
    var timestamp = this.lastUpdateTimestamp();

    // 当不存在 timestamp 的时候，证明没有更新，不需要尝试了
    if (timestamp === 0) {
      return Promise.resolve();
    }

    // 10 分钟内不需要尝试更新 token
    if ((+(new Date) - timestamp) / 1000 < 10 * 60) {
      return Promise.resolve();
    }
    return axios.get('/users/me?noTokenPass=pass')
  },
  destroy () {
    storage.remove('access_token');
    storage.remove('refresh_token');
    storage.remove('oauth_token_updated_at');
  },
  lastUpdateTimestamp () {
    var timestamp = storage.get('oauth_token_updated_at')
    if (!(+timestamp)) {
      return 0;
    }
    return timestamp;
  }
}

export default OauthToken;
