'use strict'

const path = require('path')

function isProduction () {
    return process.env.DEPLOY_ENV === 'production'
}

module.exports = {
  env: 'production',
  isProduction: isProduction(),
  baseUrl: isProduction() ? 'https://mergeek.com' : 'https://staging.mergeek.com', // 后端服务器 url
  vueServer: {
    host: 'localhost',
    port: 8080,
    autoOpenBrowser: false,
    errorOverlay: true,
    notifyOnErrors: true,
    poll: false, // https://webpack.js.org/configuration/dev-server/#devserver-watchoptions-
  },

  wechat: {
    appId: isProduction() ? 'wxd4c7a1f4634b8900' : 'wxb39b96e8572e2292',
    redirectHost: isProduction() ? 'https://mergeek.com' : 'https://staging.mergeek.com', // 微信授权，重定向域名
  },

  build: {
    // Template for index.html
    index: path.resolve(__dirname, '../dist/index.html'),

    // Paths
    assetsRoot: path.resolve(__dirname, '../dist'),
    assetsSubDirectory: 'static',
    assetsPublicPath: 'https://weaver-static.oss-cn-beijing.aliyuncs.com/',
    proxyTable: {},
    /**
     * Source Maps
     */
    productionSourceMap: true,
    // https://webpack.js.org/configuration/devtool/#production
    devtool: '#source-map',

    // If you have problems debugging vue-files in devtools,
    // set this to false - it *may* help
    // https://vue-loader.vuejs.org/en/options.html#cachebusting
    cacheBusting: true,

    cssSourceMap: true,

    // Gzip off by default as many popular static hosts such as
    // Surge or Netlify already gzip all static assets for you.
    // Before setting to `true`, make sure to:
    // npm install --save-dev compression-webpack-plugin
    productionGzip: false,
    productionGzipExtensions: ['js', 'css'],

    // Run the build command with an extra argument to
    // View the bundle analyzer report after build finishes:
    // `npm run build --report`
    // Set to `true` or `false` to always turn it on or off
    bundleAnalyzerReport: process.env.npm_config_report
  }
}
