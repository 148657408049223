var storage = {
    set(key, value) {
    	localStorage.setItem(key, JSON.stringify(value));
    },
    get(key) {
    	var value = localStorage.getItem(key);
    	if (value) {
    		var parsed = JSON.parse(value);
        if (typeof parsed == 'object' && parsed.expiredAt) {
          // 如果 Localstorage 过期了，就返回 ''
          if (new Date > new Date(parsed.expiredAt)) {
            this.remove(key);
            return '';
          } else {
            return parsed.data;
          }

        } else {
          return parsed;
        }
    	} 
    	// return JSON.parse(localStorage.getItem(key));
    	return '';
    },
    // 设置带过期时间的 localStorag
    // @param :expiration, {Integer}, 多少秒后过期
    setex(key, expiration, value) {
      var expiredAt = +(new Date) + expiration * 1000

    	localStorage.setItem(key, JSON.stringify({ data: value, expiredAt }));
    },
    remove(key) {
    	localStorage.removeItem(key)
    },

    set_s(key, value) {
      sessionStorage.setItem(key, JSON.stringify(value));
    },
    get_s(key) {
      var data = sessionStorage.getItem(key);
      if (data) {
        return JSON.parse(data);
      }
      return ''
    },
    remove_s(key) {
      sessionStorage.removeItem(key)
    }
 
}
export default storage;
