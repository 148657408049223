// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueGtm from 'vue-gtm'
import App from './App'
import router from './router'
import axios from "axios"
import interceptors from './js/interceptors'
import config from '../config'
// import 'lib-flexible/flexible.js'

Vue.config.productionTip = false
axios.defaults.withCredentials = true
axios.defaults.responseType = 'json'
axios.defaults.baseURL = config.baseUrl

/* google tag manager */
Vue.use(VueGtm, {
  id: 'GTM-KCZ59RB',
  enabled: config.isProduction
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})

import '../static/style/toast.css';
import Toast from './js/toast.js';
Vue.use(Toast);
