import axios from "axios"
import sha1 from 'js-sha1'
import Bus from '../js/bus.js'
import storage from '../js/storage.js'
import Common from '../js/common.js'
import OauthToken from '../js/oauth_token'

let Weixin = {
  /**
   * 微信授权登录，当微信用户不存在时（没有关联微信），跳转到登录页面
   */
  wechatOauth (code, self, callback) {
    console.log("进入 wechatOauth 方法")
    axios.post('/wechat/oauth', {
      code: code
    })
    .then(function (res) {
      console.log("wechatOauth 方法返回数据", res)
      storage.set('wechatBind', res.data.data.binding);

      if(res.data.data.wechat_user) {
        self.openId = res.data.data.wechat_user.openid;
        self.unionId = res.data.data.wechat_user.unionid;
        var wechatInfo = {
          nickname: res.data.data.wechat_user.nickname,
          openid:res.data.data.wechat_user.openid,
          unionid: res.data.data.wechat_user.unionid,
          headimgurl: res.data.data.wechat_user.headimgurl
        }
        storage.set('wechatInfo', wechatInfo);
        console.log('wechatInfo', wechatInfo);
        if (self.openId) {
          document.cookie = `open_id=${self.openId}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        }
      }
      if(res.data.data.binding == false) {
        self.bindConfigMessage = '绑定微信'
        self.userName = res.data.data.wechat_user.nickname;
        self.avatarUrl = res.data.data.wechat_user.headimgurl;
        self.$router.push({path: '/login'});
      }else {
        self.bindConfigMessage = '直接登录'
        OauthToken.update(res.data.data.tokens.access_token, res.data.data.tokens.refresh_token);
        var userInfo = {
          createTime: res.data.data.user.created_at,
          userName: res.data.data.user.username,
          avatar: res.data.data.user.avatar.url
        }
        storage.set('userInfo', userInfo);
        callback(res)
      }
    })
  },
  /**
   * 微信授权登录，当微信用户不存在时，把当前微信信息保存在 storage，跳过；当微信用户存在时，登录。
   * 用于给微信老用户自动登录
   */
   autoLoginForRegisteredUser (code) {
    return axios.post('/wechat/login', {
      code: code
    })
    .then(function (res) {
      var wechatInfo = res.data.data.wechat_user;
      if (wechatInfo && wechatInfo.open_id) {
        document.cookie = `open_id=${wechatInfo.open_id}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
      }
      if(res.data.data.binding == true) {
        OauthToken.update(res.data.data.tokens.access_token, res.data.data.tokens.refresh_token);
        var userInfo = {
          createTime: res.data.data.user.created_at,
          userName: res.data.data.user.username,
          avatar: res.data.data.user.avatar.url
        }
        storage.set('userInfo', userInfo);
      } else {
        if (wechatInfo) {
          if (wechatInfo.union_id) {
            storage.set('unid', wechatInfo.union_id)
          }
          if (wechatInfo.open_id && wechatInfo.union_id) {
            storage.set('wechatBind', false);
            var wechatInfo = {
              openid: wechatInfo.open_id,
              unionid: wechatInfo.union_id,
            }
            storage.set('wechatInfo', wechatInfo);
          }
        }
      }
    });
   },

  /**
   * 微信授权登录，当微信用户不存在时，自动创建一个微信用户
   */
  wechatOauthAuto (code, projectId, callback) {
    var data = {
      code: code,
      project_id: projectId
    }
    axios.post('/wechat/register', data)
    .then((res) => {
      storage.set('wechatBind', true);
      OauthToken.update(res.data.data.tokens.access_token, res.data.data.tokens.refresh_token);
      var userInfo = {
        createTime: res.data.data.user.created_at,
        userName: res.data.data.user.username,
        avatar: res.data.data.user.avatar.url
      }
      storage.set('userInfo', userInfo);
      var wechatInfo = res.data.data.wechat_user;
      if (wechatInfo && wechatInfo.openid) {
        document.cookie = `open_id=${wechatInfo.openid}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
      }
      callback(res);
    })
    .catch((err) => {
      console.log(err.response);
    })
  },
  wechatBind (openId, unionId, avatarUrl, callback, silent = false) {
    axios.post('/wechat/bind', {
      open_id: openId,
      union_id: unionId,
      avatar_url: avatarUrl
    })
    .then(function (res) {
      console.log('wechatBind', res)
      storage.set('wechatBind', true);
      callback(res);
    })
    .catch(function (err) {
      console.log('wechatBind error', err)
      if (!silent) {
        alert(err.response.data.error_msg)
      }
      callback();
    })
  },
  wechatUnBind () {
    axios.delete('wechat/bind')
    .then(function (res) {
      console.log('unbind wechat', res)
      storage.set('wechatBind', false);
    })
  },
  getQRcode (self) {
    axios.get('/wechat/qrcode?type=subscribe')
    .then(function (res) {
      var weixin_url = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket='+res.data.data.qrcode_ticket;
      self.QRcodeUrl = weixin_url;
      // weixin_url 发送给 investmentMessage.vue
      Bus.$emit('weixin_url', weixin_url);
      storage.set('weixin_url', weixin_url);
    })
  },
  getQRcodeMethod (callback, errMsg) {
    if(errMsg) {
      storage.remove('weixin_url');
    }
    var qrcode = storage.get('weixin_url');
    if(qrcode) {
      callback(qrcode);
    }else {
      axios.get('/wechat/qrcode?type=subscribe')
      .then(function (res) {
        var weixin_url = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket='+res.data.data.qrcode_ticket;
        storage.set('weixin_url', weixin_url);
        callback(weixin_url);
      })
    }
  },
  getJsapiTicket () {
    var ticketInfo = storage.get('jsapiTicket')
    if (ticketInfo) {
      var expiredAt = new Date(ticketInfo.expiredAt)
      if (new Date < expiredAt) {
        return Promise.resolve(ticketInfo.ticket)
      }
    }
    return axios.get('/wechat/jsapi_ticket')
    .then(function (res) {
      var data = res.data.data
      if (data) {
        storage.set('jsapiTicket', { ticket: data.ticket, expiredAt: data.expired_at })
        return data.ticket
      }
    })
  },
  // jssdk config 参数
  getWechatConfig (url) {
    return this.getJsapiTicket().then(function (ticket) {
      let attrs = {
        nonceStr: Common.randomStr(16),
        jsapi_ticket: ticket,
        timestamp: +(new Date),
        url: url
      }
      var str = Object.keys(attrs).sort().map(k => `${k.toLowerCase()}=${attrs[k]}`).join('&')
      str = sha1(str)
      attrs.signature = str
      return attrs
    })
  }
}

export default Weixin;