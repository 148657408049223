<template>
  <div class="wechatAuth">
    <!-- <div>
      <span>登录成功</span>
      <div>
        <div class="wechatAvatar">
          <img :src="avatarUrl" alt="">
        </div>
        <span>{{userName}}</span>
      </div>
    </div>
    <div>
      <button class="backBtn" @click='back'>返回</button>
    </div>
    <div>
      <button class="backBtn" @click='bindWeChat'>{{bindConfigMessage}}</button>
    </div> -->
    <!-- <div>
      <span>请稍等</span>
    </div> -->
    <div class="double-bounce">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
    <div class="tip">
      <span>请稍等</span>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import Weixin from '../js/weixin.js'
  import Bus from '../js/bus.js'
  import storage from '../js/storage'
  import Utils from '../js/utils.js'

  export default {
    name: 'wechatAuth',
    data () {
      return {
        bindConfigMessage: '',
        openId: '',
        unionId: '',
        source_path: '',
        userName: '',
        avatarUrl: '',
      }
    },
    methods: {
      getCode: function () {
        var self = this;
        this.source_path = storage.get('current_path');
        // 去掉这个状态，似乎已经不需要自动投资了
        // var noInvest = storage.get_s('noInvest');
        
        console.log(this.$router.history.current)
        console.log(this.source_path)
        var register = Utils.getQuery('register');
        console.log("是否有 register", register)
        // var project_id = this.source_path.split('=')[1] && this.source_path.split('=')[1].split('&')[0];
        var project_id = storage.get_s('projectId') || Utils.getQuery('project_id', this.source_path) || Utils.getQuery('projectId', this.source_path);
        var code = Utils.getQuery('code');
        var state = Utils.getQuery('state');

        if (code && state === 'auth_only') {
          return Weixin.autoLoginForRegisteredUser(code)
            .then(function () {
              Utils.returnToSourcePath(self);
            })
            .catch(function (err) {
              console.log(err);
              Utils.returnToSourcePath(self);
            });
        }

        console.log("是否有 code", code, project_id)
        if(code && register) {
          Weixin.wechatOauthAuto(code, project_id, (res) => {
            Utils.returnToSourcePath(self);
            storage.remove_s('noInvest');
            // if(noInvest) {
            //   Utils.returnToSourcePath(self);
            //   storage.remove_s('noInvest');
            //   return;
            // }
            // self.investment(project_id, self.source_path);
          })
          return;
        }
        if(code) {
          console.log("有 code")
          Weixin.wechatOauth(code, this, function (res) {
            Utils.returnToSourcePath(self)
          })
        } else {
          self.$router.push({ path: '/' })  
        }
      },
      investment: function (projectId, targetPath) {
        var data = {
          project_id: projectId,
        }
        console.log(data, targetPath)
        if(!projectId) {
          var c_name = this.source_path == '/' ? 'projectSummary' : '';
          this.$router.push({
            path: targetPath, 
            name: c_name,
            query: {regSuccess: true}
          });
          return;
        }
        axios.post('/investments', data)
        .then((res) => {
          console.log("在 wechatAuth 的投资信息", res)
          this.$router.push({
            path: targetPath, 
            name: 'project', 
            params: {projectId}, 
            query: {regSuccess: true}
          });
          storage.remove_s('projectId');
        })
        .catch((err) => {
          console.log(err)
          if(err.response.data.error_type == 'InvestmentRepeatError') {
            this.$router.push({
              path: targetPath,
              query: {projectId: projectId, investmentRepeat: true}
            });
          }
          this.$toast.center(err.response.data.error_msg)
        })
      },
      back: function () {
        window.location.href = 'http://localhost:8080/#'+this.source_path
      },
      bindWeChat: function () {
        console.log(this.openId)
        var self = this;
        Weixin.wechatBind(this.openId, this.unionId, function (res) {
          self.$router.push({path: self.source_path, query:{projectId:''}})
        });
      },
      
    },
    mounted: function () {
      this.getCode();
    }
  }
</script>

<style scoped>
  .wechatAuth {
    width: 375px;
    height: 830px;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #000;
  }
  .backBtn {
    width: 304px;
    height: 40px;
    border-radius: 5px;
    border: none;
    background: #5C98F2;
    margin-top: 30px;
    font-size: 16px;
    color: #fff;
  }
  .wechatAvatar {
    width: 48px;
    height: 48px;
    margin: 5px auto;
    border: 1px solid #fff;
    border-radius: 24px;
  }
  .wechatAvatar img {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
  .tip {
    font-size: 20px;
  }








</style>