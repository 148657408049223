import axios from "axios"
import storage from '../js/storage'
import Bus from '../js/bus.js'
import util from '../js/utils'
import Common from '../js/common'
import OauthToken from '../js/oauth_token'

var request_pass_word = 'noTokenPass=pass'

axios.defaults.headers.common['Time'] = util.getTimeParams();
axios.defaults.headers.common['Access-Token'] = storage.get('access_token');
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.retry = 1;

function showLogin(err) {
	storage.remove('userInfo');
	var requestUrl = err.response.request.responseURL;
	var requestUrl_keyword = requestUrl.split('?').pop();
	var no_token_pass = util.getQuery('noTokenPass', requestUrl);
  if(no_token_pass == 'pass') {
  	OauthToken.destroy();
  	console.log(requestUrl.split('/').pop(), '没有token也没有关系')
  	return;
  }
	// if(request_pass_word == requestUrl_keyword) {
	// 	console.log(requestUrl.split('/').pop(), '没有token也没关系')
	// 	return;
	// }
	var data = {
		isShow: true
	}
	Common.logout()
	// emit 是去 login.vue
	if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) && !/MicroMessenger/i.test(navigator.userAgent)) {
	  console.log('所用设备：mobile')
	  // window.location.href = 'http://localhost:8080/#/mobileLogin';
	  window.location.href = `${window.location.protocol}//${window.location.host}/login`;
	}else if(/MicroMessenger/i.test(navigator.userAgent)) {
		console.log('所用设备：wechat')
		util.wechatAuth()
	}else {
	  console.log('所用设备：pc')
	  Bus.$emit('isShowLogin', data);
	  return;
	}	
}
axios.interceptors.request.use(function (config) {
	config.headers['Access-Token'] = storage.get('access_token');

	// 自动在请求前更新 token，防止过期
  if (
    config.url.indexOf('/users/me') === 0 ||
    !config.headers['Access-Token']
  ) {
    return config;
  }

  // 15 分钟内不会自动登录
  if (storage.get_s('last_auto_login_at')) {
    var lastAutoLoginAt = new Date(storage.get_s('last_auto_login_at'));
    if (new Date() - lastAutoLoginAt < 15 * 60 * 1000) {
      return config;
    }
  }
  storage.set_s('last_auto_login_at', new Date());
  return axios
    .get('/users/me?noTokenPass=pass')
    .then(function () {
      config.headers['Access-Token'] = storage.get('access_token');
      return config;
    })
    ['catch'](function () {
      return config;
    });
});
axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
	var config = err.config;
	if (["OauthTokenInvalidError", "OauthTokenExpiredError"].includes(err.response.data.error_type)) {
		var tokens = OauthToken.get();
		var refresh_token = tokens.refresh_token;
		var access_token = tokens.access_token;
		if(!refresh_token) {
			console.log('alert login vue')
			showLogin(err);
			return;
		}
		return axios.put('/oauth', {
			refresh_token: refresh_token,
			access_token: access_token
		})
		.then(function(res) {
			OauthToken.update(res.data.data.tokens.access_token, res.data.data.tokens.refresh_token);
			return retry_request(config, err);
		})
		.catch(function(res) {
			// isEqual 指的是第二个同样的请求时，内存保存的 access_token 与已经更新的 storage 的 access_token 是否相同
			// 如果不相同说明已经更新成功，不需要执行下去，否则就要删除缓存
			// 这个是专门处理多个同时 oauth 接口
			var isEqual = (access_token === OauthToken.get().access_token);
			if(res.response.status === 404 && !isEqual) {
				return retry_request(config, res);
			}
			showLogin(err);
		})
	}else if(["Exception"].includes(err.response.data.error_type)) {
		return Promise.reject(err);
	}
 	return retry_request(config, err);
})
function retry_request(config, err) {
	if(!config || !config.retry) return Promise.reject(err);
    config.__retryCount = config.__retryCount || 0;
    if(config.__retryCount >= config.retry) {
      return Promise.reject(err);
    }
    config.__retryCount += 1;
    return axios(config);
}