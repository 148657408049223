import axios from "axios"
import urlAssemble from '../js/urlAssemble'
import Weixin from '../js/weixin'
import config from '../../config'
import Common from '../js/common'
import storage from '../js/storage'
import Bus from '../js/bus'

let Utils = {
  addScreen: function (self) {
    var ua = navigator.userAgent;
    self.layerActive = true;
    if(/Safari/.test(ua) && !/Chrome/.test(ua) && !/Android/.test(ua) && !/MicroMessenger/i.test(ua) && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i)) {
      self.addScreenTipShow = true;
      setTimeout(() => {
        self.addScreenTipActive = true;
      }, 300)
    }else if(/MicroMessenger/i.test(ua)) {
      self.safariShow = true;
      setTimeout(() => {
        self.safariShareActive = true;
      })
    }else {
      self.$router.push({name: 'collect_message'});
    }
  },
  hideAddScreen: function (self, c) {
    if(c == 'addScreenTip') {
      self.addScreenTipShow = false;
      self.addScreenTipActive = false;
    }else if(c == 'safariTip') {
      self.safariShow = false;
      self.safariShareActive = false;
    }
    setTimeout(() => {
      self.layerActive = false;
    }, 300)
  },
  showShareComponent: function (self) {
    self.layerActive = true;
    setTimeout(() => {
      self.pixelWarShareActive = true;
    }, 300)
  },
  hideShareComponent: function (self) {
    self.pixelWarShareActive = false;
    setTimeout(() => {
      self.layerActive = false;
    }, 300)
  },
  isPWA: function () {
    var mqStandAlone = '(display-mode: standalone)';
    return (navigator.standalone || window.matchMedia(mqStandAlone).matches);
  },
  saveCurrentPath (self) {
    var url = Utils.delQuery(self.$router.history.current.fullPath, 'auth')
    url = Utils.delQuery(url, 'force_auth')
    storage.set('current_path', url)
  },
  tryAutoLoginForWechat (self) {
    this.saveCurrentPath(self);
    // 当在微信，且带有参数 '?auth=1' 则自动微信授权
    var needAuth = this.getQuery('auth') == '1';
    var forceAuth = this.getQuery('force_auth') == '1';
    if ((needAuth || forceAuth) && /MicroMessenger/i.test(navigator.userAgent)) {
      this.wechatAuth('auth_only');
      return true
    }
    return false
  },
  startScroll () {
    var mo = function(e){e.preventDefault()};
    document.body.style.overflow = '';
    // document.removeEventListener("touchmove", mo, false);
  },
  stopScroll: function () {
    var mo = function(e){e.preventDefault()};
    console.log(document.documentElement.clientHeight)
    if(document.documentElement.clientHeight >= 690) {
      document.body.style.overflow = 'hidden';
      // document.addEventListener("touchmove", mo, false);
    }
  },
  getQuery_ex (query) {
    var current_url = window.location.href;
    var url = '';
    var params = '';
    current_url = current_url.replace(/\/#/g, '');
    url = new URL(current_url);
    params = new URLSearchParams(url.search);
    return params.get(query);
  },
  getQuery (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
  delQuery (url, ref) {
    var str = "";
    if (url.indexOf('?') != -1)
      str = url.substr(url.indexOf('?') + 1);
    else
      return url;
    var arr = "";
    var returnurl = "";
    var setparam = "";
    if (str.indexOf('&') != -1) {
      arr = str.split('&');
      for (var i in arr) {
        if (arr[i].split('=')[0] != ref) {
          returnurl = returnurl + arr[i].split('=')[0] + "=" + arr[i].split('=')[1] + "&";
        }
      }
      return url.substr(0, url.indexOf('?')) + "?" + returnurl.substr(0, returnurl.length - 1);
    }
    else {
      arr = str.split('=');
      if (arr[0] == ref)
        return url.substr(0, url.indexOf('?'));
      else
        return url;
    }
  },
  getQueryPromise (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return Promise.resolve(null);
    if (!results[2]) return Promise.resolve('');
    return Promise.resolve(decodeURIComponent(results[2].replace(/\+/g, ' ')));
  },
  home (obj, project_id) {
    let state = {
      title: '',
      url: ''
    }
    window.history.pushState(state, state.title, state.url);
    window.onpopstate = () => {
      obj.$router.push({path: '/', name: 'projectSummary', params: { projectId: project_id }})
    };
  },
  zeroFill (timeNum) {
    return timeNum < 10 ? '0'+timeNum : timeNum;
  },
  setExpired (expired_name, callback) {
    var expiredName = storage.get(expired_name);
    if(expiredName) {
      if(new Date < new Date(expiredName)) {
        return;
      }else {
        callback();
        var expiredAt = new Date();
        expiredAt.setDate(expiredAt.getDate() + 7);
        storage.set(expired_name, expiredAt);
      }
    }else {
      callback();
      var expiredAt = new Date();
      expiredAt.setDate(expiredAt.getDate() + 7);
      storage.set(expired_name, expiredAt);
    }
  },
  setExpiredWithDay (expired_name, day, callback) {
    var expiredName = storage.get(expired_name);
      if(expiredName) {
        var today = new Date().toLocaleDateString();
        expiredName = new Date(expiredName).toLocaleDateString();
        if(new Date(today) < new Date(expiredName)) {
          return;
      }else {
        callback();
        var expiredAt = new Date();
        expiredAt.setDate(expiredAt.getDate() + day);
        storage.set(expired_name, expiredAt);
      }
    }else {
      callback();
      var expiredAt = new Date();
      expiredAt.setDate(expiredAt.getDate() + day);
      storage.set(expired_name, expiredAt);
    }
  },
  getRandomNum (min, max) {
    var Range = max - min;
    var Rand = Math.random();
    return(min + Math.round(Rand * Range));
  },
  checkVIPstatus () {
    var vip = storage.get('ted_vip');
    var expiredAt = '';
    var today = new Date();
    if(vip) {
      expiredAt = vip.data.vip_expired_at;
      var storage_expiredAt = vip.expreid_at;
      // console.log('缓存', vip)
      if((new Date(today) < new Date(storage_expiredAt)) && (expiredAt === null)) {
        // console.log('not vip expire 1 mins')
        return Promise.resolve('expire');
      }
      if((new Date(today) < new Date(expiredAt)) && (expiredAt !== null)) {
        // console.log("not expire")
        return Promise.resolve('notExpire');
      }
      if((new Date(today) >= new Date(expiredAt)) && (expiredAt !== null)) {
        // console.log("expire need renewal")
        storage.remove('ted_vip');
        return Promise.resolve('expire_renewal');
      }
      if((new Date(today) >= new Date(storage_expiredAt)) && (expiredAt === null)) {
        // console.log('大于缓存时间，且没有真实过期时间')
        storage.remove('ted_vip');
        return Promise.resolve('expire');
      }
    }else {
      return axios.get('/ted_membership/vip')
      .then((res) => {
        var re = res.data.data;
        // console.log(111,re)
        var ted_vip = {
          data: {}
        };
        if(re.expired_at) {
          ted_vip.data.vip = re.is_vip;
          ted_vip.data.vip_expired_at = re.expired_at;
          // let temp_time = new Date(re.expired_at);
          // var fiveDays = temp_time.setDate(temp_time.getDate()-1);
          let temp_time = new Date();
          let hours = Math.abs((temp_time - new Date(re.expired_at))/1000/3600);
          hours = Math.floor(hours);
          hours = Math.min(24, hours);
          var oneDay = temp_time.setHours(temp_time.getHours()+hours);
          ted_vip.expreid_at = new Date(oneDay);
          storage.set("ted_vip", ted_vip);

          var is_expire_data = '';
          expiredAt = re.expired_at;
          if(new Date(today) < new Date(expiredAt)) {
            is_expire_data = 'notExpire';
          }else {
            is_expire_data = 'expire_renewal';
          }
          return is_expire_data;
        }
        if(!re.expired_at || !re.is_vip) {
          ted_vip.data.vip = re.is_vip;
          ted_vip.data.vip_expired_at = null;
          let temp_time = new Date();
          // var oneMins = temp_time.setMinutes(temp_time.getMinutes()+1);
          var oneMins = temp_time.setSeconds(temp_time.getSeconds()+20);
          ted_vip.expreid_at = new Date(oneMins);
          storage.set("ted_vip", ted_vip);
          return 'expire';
        }
      })
    }
  },
  updateMode () {
    var mode = Utils.getQuery('mode');
    if (mode == 'follow') {
      storage.set_s('sMode', 'follow');
    } else if (mode == 'woa') {
      // wechat official account
      storage.set_s('sMode', 'woa');
    }
  },
  // 微信授权，重定向到微信服务器，获取授权 code
  // type 'normal'，正常授权，当用户没有关联微信时，会弹出登录框
  // type 'auth_only', 仅仅是授权，如果用户没有关联微信，什么都不做，已经关联微信的用户，自动登录
  wechatAuth (type = 'normal') {
    var appId = config.wechat.appId
    var authType = 'snsapi_userinfo';
    var redirectUrl = encodeURIComponent(urlAssemble.wechat_redirect);
    var state = '';
    if (type === 'auth_only') {
      authType = 'snsapi_base';
      state = 'auth_only';
    }
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&state=${state}&scope=${authType}#wechat_redirect`;
  },
  returnToSourcePath (self, skipPaths = []) {
    const sourcePath = storage.get('current_path');
    let eventEnrollerPath = storage.get_s('event_enroller_path');
    if (eventEnrollerPath) {
      return self.$router.push({ path: eventEnrollerPath })
    }
    if (!sourcePath) {
      location.href = '/'
      return
    }
    if (self.$router.history.current.path === sourcePath) {
      location.href = '/'
      return
    }
    if (skipPaths.includes(sourcePath)) {
      location.href = '/'
      return
    }
    if (sourcePath.indexOf('http') === 0) {
      window.location.href = sourcePath
    } else {
      self.$router.push({ path: sourcePath })
    }
  },
  prepareWechatShare: function (wx, self, currentUrl, options = {}) {
    options = Object.assign({
      title: '这是有史以来最棒的网站！', // 分享标题
      desc: '和我一起发现更好的世界吧', // 分享描述
      link: config.baseUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: '' // 分享图标
    }, options)
    if (!Common.isWechat()) {
      return
    }
    Weixin.getWechatConfig(currentUrl)
    .then(function (attrs) {
      attrs.appId = config.wechat.appId
      attrs.jsApiList = [
        'updateAppMessageShareData', 'onMenuShareAppMessage', 'onMenuShareQQ',
        'updateTimelineShareData', 'onMenuShareTimeline', 'onMenuShareQZone'
      ]
      attrs.debug = false
      wx.config(attrs)
    })
    .catch((err) => {
      console.log('[projectList] error in prepareWechatShare', err)
    })

    wx.ready(function () {
      /*
       * 很 egg pain 的微信分享接口，同时兼容了老版本和新版本，文档见：
       * https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141115
       */

      /* “分享给朋友”及“分享到QQ” */
      // 新版微信支持，分享给朋友及分享到QQ
      wx.updateAppMessageShareData({
        title: options.title,
        desc: options.desc,
        link: options.link,
        imgUrl: options.imgUrl
      }, function(res) {
        self.$toast.center('分享成功');
      })
      // 老版本接口, 分享到微信朋友据说即将废弃，不过现在先兼容一下
      wx.onMenuShareAppMessage({
        title: options.title,
        desc: options.desc,
        link: options.link,
        imgUrl: options.imgUrl,
        type: '', // 分享类型,music、video或link，不填默认为link
        dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
        success: function () {
          self.$toast.center('分享成功');
        }
      })
      // 老版本接口, 分享到QQ朋友据说即将废弃，不过现在先兼容一下
      wx.onMenuShareQQ({
        title: options.title,
        desc: options.desc,
        link: options.link,
        imgUrl: options.imgUrl,
        success: function () {
          self.$toast.center('分享成功');
        }
      })

      /* “分享到朋友圈”及“分享到QQ空间” */
      // 新版微信支持，分享到朋友圈及分享到QQ空间
      wx.updateTimelineShareData({
        title: options.title,
        link: options.link,
        imgUrl: options.imgUrl
      }, function(res) {
        self.$toast.center('分享成功');
      })
      // 老版本接口，分享到朋友圈，据说即将废弃，不过现在先兼容一下
      wx.onMenuShareTimeline({
        title: options.title,
        link: options.link,
        imgUrl: options.imgUrl,
        success: function () {
          self.$toast.center('分享成功');
        }
      })
      // 老版本接口，分享到QQ空间，据说即将废弃，不过现在先兼容一下
      wx.onMenuShareQZone({
        title: options.title,
        link: options.link,
        imgUrl: options.imgUrl,
        desc: options.desc,
        success: function () {
          self.$toast.center('分享成功');
        }
      });
    })
  },
  timeGenerator: function () {
    return 'xxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    })
  },
  getTimeParams: function () {
    var time = storage.get('Time');
    if(!time) {
      time = Utils.timeGenerator();
      storage.set('Time', time);
    }
    return time;
  },

  /**
   * 入口 url 的重定向处理，当用户第一次访问，或者刷新当前页会触发这个方法
   *
   * 注意，这个方法会自动去掉别人的 Time 并带上自己的 Time
   * 统计相关的逻辑完全交给后端处理
   */
  redirectUrl: function (self) {
    var skipPathName = window.location.pathname.split('/')[1];
    let skipPathsArr = ['fan_activities', 'leaderboard', 'd', 's'];
    var storageTime = storage.get('Time');

    var queries = Utils.searchToQueries(window.location.search);

    var urlTime = queries.time;
    queries.time = storageTime; // 替换 time
    console.log('pathname: ', window.location.pathname);

    // 对于 "/?project_id=:guid" 的处理
    if (window.location.pathname === "/" && queries.project_id) {
      var projectId = queries.project_id;
      delete queries.project_id;
      if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        window.location.href = '/projects/'+projectId+'?time='+storageTime;
      } else {
        self.$router.replace({ path: '/projects/' + projectId, query: queries });
      }
      return;
    }

    if (urlTime !== storageTime) {
      if (urlTime && urlTime.length > 0) {
        var data = {
          time: urlTime,
          page: window.location.pathname + window.location.search
        };
        axios.post('/track_time', data);
      }
      if (window.location.pathname !== "/" && skipPathsArr.indexOf(skipPathName) == -1) {
        self.$router.replace({ path: window.location.pathname, query: queries });
      }
    }
  },

  // window.location.search "?a=1&b=2"
  // =>
  // { a: 1, b: 2 }
  searchToQueries: function (search) {
    var querypairs = search.replace('?', '').split('&');
    var queries = {};
    for (var i = 0; i < querypairs.length; i++) {
      if (querypairs[i] && querypairs[i].length > 0) {
        var kv = querypairs[i].split('=');
        queries[kv[0]] = kv[1];
      }
    }
    return queries;
  },

  // { a: 1, b: 2 }
  // =>
  // "?a=1&b=2"
  queriesToSearch: function (queries) {
    var keys = Object.keys(queries);
    var kvs = [];
    for (var i = 0; i < keys.length; i++) {
      kvs.push(keys[i] + '=' + queries[keys[i]]);
    }
    var search = kvs.join('&');
    if (search && search.length > 2) {
      return '?' + search;
    }
    return '';
  },

  // 当 url 里面带有 'access_token'，会登出，
  // 然后尝试用这个来获取登录状态
  // 成功后会更新 localStorage 里面的 userInfo
  // 无论成功失败都会去掉 url 里面的 access_token
  tryLoginWithUrlAccessToken() {
    return Utils.getQueryPromise('access_token').then(function (urlAccessToken) {
      if (!urlAccessToken) {
        return;
      }
      Common.logout();
      storage.set('access_token', urlAccessToken);
      return axios
        .get('/users/me?noTokenPass=pass')
        .then(function (res) {
          var userInfo = {
            createTime: res.data.data.created_at,
            userName: res.data.data.username,
            avatar: res.data.data.avatar.url,
          };

          storage.set('userInfo', userInfo);
        })
        ['finally'](function () {
          var urlWithoutAccessToken = Utils.delQuery(location.href, 'access_token');
          window.history.replaceState({}, document.title, urlWithoutAccessToken);
        });
    });
  },
  followAuth() {
    var mode = storage.get_s('sMode');
    if(mode == 'follow') {
      return Utils.tryLoginWithUrlAccessToken()
    }
    return Promise.resolve()
  },
  leaderboardFollowAuth() {
    return new Promise((resolve, reject) => {
      Utils.getQueryPromise('access_token').then(function (urlAccessToken) {
        if (!urlAccessToken) {
          return;
        }
        Common.logout();
        storage.set('access_token', urlAccessToken);
        axios
          .get('/users/me?noTokenPass=pass')
          .then(function (res) {
            var userInfo = {
              createTime: res.data.data.created_at,
              userName: res.data.data.username,
              avatar: res.data.data.avatar.url,
              id: res.data.data.id
            };

            storage.set('userInfo', userInfo);
          })
          ['finally'](function () {
            var urlWithoutAccessToken = Utils.delQuery(location.href, 'access_token');
            window.history.replaceState({}, document.title, urlWithoutAccessToken);
          });
      });
      resolve();
    })
  },
  // 这个方法用于非第一次进入精选详情页时，缓存 sessionStorage 不展示信息流
  updateCardFlowMark() {
    var pathname = window.location.pathname;
    if(!pathname.includes("/projects")) {
      storage.set_s('showCardFlow', false);
      storage.set('previous_path', location.pathname);
    }
  },
  // 获取 user log token
  getUlc() {
    var ulc = storage.get('ulc');
    if (ulc.length > 0) {
      return Promise.resolve(ulc);
    }
    
    var queries = '';
    var unionId = storage.get('unid');

    if (!storage.get('access_token') && !unionId) {
      return Promise.resolve('');
    }

    if (unionId && unionId.length > 3) {
      queries = '?unid=' + unionId;
    }
  
    return axios
      .get('/users/ulc' + queries)
      .then(function (res) {
        ulc = res.data.data.ulc
        if (ulc) {
          storage.setex('ulc', 3600 * 3, ulc);
          return ulc;
        }
      })
  },
  // 会在下载链接追加一个 ulc 参数，方便记录爱好者任务
  // @param :openAttr, {String}, '_top' : '_blank'
  downloadProject(link, openAttr = '_top') {
    return this.getUlc().then(function (ulc) {
      if (ulc) {
        var delimiter = link.includes('?') ? '&' : '?';
        link = `${link}${delimiter}ulc=#{ulc}`;
      }
      window.open(this.downloadArr[0].url, openAttr);
    })
  },
  // 获取是否打卡，需要已登录
  getDailyTask(self) {
    // 获取打卡的时间，时间是今天就不用再调接口
    var todayCheckinTime = storage.get('todayCheckinTime');
    var date = new Date();
    var isToday = false;
    if(todayCheckinTime) {
      var tct = new Date(todayCheckinTime);
      isToday = tct.getDate() == date.getDate() && tct.getMonth() == date.getMonth() && tct.getFullYear() == date.getFullYear();
    }
    if(!Common.isLogin()) {
      self.$toast.center('请点击头像登录');
      return;
    }
    if(isToday) {
      self.$toast.center('你今天已经打过卡啦');
    }
    if(Common.isLogin() && !isToday) {
      axios.get('/user_centers/daily_tasks')
      .then((res) => {
        var isCheckin = res.data.data.tasks.checkin.done;
        if(!isCheckin) {
          axios.post('/user_checkins')
          .then((checkinRes) => {
            if(checkinRes.data.data.checkin_success) {
              Bus.$emit('gemCardCheckin', true);
              storage.set('todayCheckinTime', new Date());
            }
          })
        }
      })
    }
  }

}

export default Utils;