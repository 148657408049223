import Vue from 'vue'
import Router from 'vue-router'
import WechatAuth from '@/components_mobile/wechatAuth'

let MobileHome = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/MobileHome')
let MobileProject = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/project')
let MobileRegistration = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/easyRegistration')
let MobileLogin = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/login')
let MobileInvestment = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/investmentMessage')
let MobileWechatAuth = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/wechatAuth')
let MobileProjectList = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/projectList')
let MobileLoginNew = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/mobileLogin')
let MobileFirstChoice = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/mobileFirstChoice')
let MobileWechatBind = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/wechatBindPage')
let MobileGlobalSelection = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/globalSelection')
let MobileProjectSummary = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/projectSummary')
let MobileProjectB = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/project_b')
let MobileStore = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/store')
let MobileHornActivity = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/hornActivity')
let MobileProjectHunt = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/project_hunt')
let MobileProjectGlobal = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/project_global')
let MobileProjectGlobal2 = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/project_global_2')
let MobileCollectMessage = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/collect_message')
let MobileProjectVote = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/project_vote')
let MobileDesktop = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/desktop')
let MobileLinkPhone = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/link_phone')
let MobilePixelPainter = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/pixel_painter')
let MobileCardFlow = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/card_flow')
let MobileSelectedProject = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/project_selected')
let MobileUserCenter = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/user_center')
let MobileMembershipInviteCode = () => import(/* webpackChunkName: 'chunk-mobile' */ '@/components_mobile/membership_invite_code')

let MobileTed = () => import(/* webpackChunkName: 'chunk-ted' */ '@/components_mobile/daily_ted')
let MobileTedLesson = () => import(/* webpackChunkName: 'chunk-ted' */ '@/components_mobile/ted_lesson')
let MobileTedVocabulary = () => import(/* webpackChunkName: 'chunk-ted' */ '@/components_mobile/vocabulary_book')
let MobileTedVocabularyCollect = () => import(/* webpackChunkName: 'chunk-ted' */ '@/components_mobile/vocabulary_collect')
let MobileUnlockTed = () => import(/* webpackChunkName: 'chunk-ted' */ '@/components_mobile/unlock_ted')
let MobileAndroid = () => import(/* webpackChunkName: 'chunk-client' */ '@/components_mobile/daily_android')
let MobilePromo = () => import(/* webpackChunkName: 'chunk-promo' */ '@/components_mobile/promo_list')
let MobilePromoDetail = () => import(/* webpackChunkName: 'chunk-promo' */ '@/components_mobile/promo_detail')
let MobilePromo2 = () => import(/* webpackChunkName: 'chunk-promo' */ '@/components_mobile/promo_list_2')
let MobileLeaderboard = () => import(/* webpackChunkName: 'chunk-promo' */ '@/components_mobile/leaderboard')
let MobileProjectDetail = () => import(/* webpackChunkName: 'chunk-promo' */ '@/components_mobile/project_detail')
let MobileSpage = () => import(/* webpackChunkName: 'chunk-promo' */ '@/components_mobile/spage')
let MobileEventEnroller = () => import(/* webpackChunkName: 'chunk-promo' */ '@/components_mobile/event_enroller')

let PCHome = () => import(/* webpackChunkName: 'chunk-pc' */ '@/components_pc/Home')
let PCProject = () => import(/* webpackChunkName: 'chunk-pc' */ '@/components_pc/project_hunt_pc')
let PCCards = () => import(/* webpackChunkName: 'chunk-pc' */ '@/components_pc/cards')
let PCTip = () => import(/* webpackChunkName: 'chunk-pc' */ '@/components_pc/tipComponent')
let PCPromoDetail = () => import(/* webpackChunkName: 'chunk-promo' */ '@/components_pc/project_detail_pc')
let PCSpage = () => import(/* webpackChunkName: 'chunk-promo' */ '@/components_pc/spage_pc')

let WPDeveloperDashboard = () => import(/* webpackChunkName: 'chunk-db' */ '@/wp/developer_dashboard')
let WPLogin = () => import(/* webpackChunkName: 'chunk-db' */ '@/wp/login')
let WPPromos = () => import(/* webpackChunkName: 'chunk-db' */ '@/wp/promos')
let WPAnalyze = () => import(/* webpackChunkName: 'chunk-db' */ '@/wp/analyze')
let WPCreate = () => import(/* webpackChunkName: 'chunk-db' */ '@/wp/promo_form')

// 单独一份资源
let ProjectFrom = () => import(/* webpackChunkName: 'chunk-pcenter' */ '@/wp/project_form')
let PricePromoFrom = () => import(/* webpackChunkName: 'chunk-pcenter' */ '@/wp/price_promo_form')
let ApplyFanActivityForm = () => import(/* webpackChunkName: 'chunk-pcenter' */ '@/wp/apply_fan_activity_form')
let PromoCenter = () => import(/* webpackChunkName: 'chunk-pcenter' */ '@/wp/promo_center')
let PromoFormSubmitted = () => import(/* webpackChunkName: 'chunk-pcenter' */ '@/wp/promo_form_submitted')

let PCNewHome2 = () => import(/* webpackChunkName: 'chunk-pc' */ '@/wp/home_page_2')

Vue.use(Router)

let a = 'pc'
if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
  a = 'mobile'
}else if(/MicroMessenger/i.test(navigator.userAgent)) {
  a = 'mobile'
}else {
  a = 'pc'  
}
var routes = [];
if(a == 'pc') {
  routes = [
    {
      path: '/home',
      name: 'Home',
      component: PCHome
    },
    // {
    //   path: '/projects/:projectId',
    //   name: 'project',
    //   component: PCHome
    // },
    // {
    //   path: '/loginReg',
    //   name: 'loginReg',
    //   component: PCHome
    // },
    // {
    //   path: '/discoverProject',
    //   name: 'discoverProject',
    //   component: PCHome
    // },
    {
      path: '/cards',
      name: 'cards',
      component: PCCards
    },
    {
      path: '/daily_ted',
      name: 'daily_ted',
      component: PCTip
    },
    {
      path: '/teds',
      name: 'ted_lesson',
      component: PCTip
    },
    {
      path: '/teds/:id',
      name: 'ted_lesson',
      component: PCTip
    },
    {
      path: '/android_selecteds',
      name: 'daily_android',
      component: MobileAndroid
    },
    {
      path: '/project_vote',
      name: 'project_vote',
      component: MobileProjectVote
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: WPDeveloperDashboard
    },
    {
      path: '/d/login',
      name: 'login',
      component: WPLogin
    },
    {
      path: '/d/promos/:id',
      name: 'promos',
      component: WPPromos
    },
    {
      path: '/d/promo_form',
      name: 'promo_form',
      component: WPCreate
    },
    {
      path: '/home_v2',
      name: 'home_page_2',
      component: PCNewHome2
    },
    {
      path: '/projects/:projectId',
      name: 'project',
      component: PCNewHome2
    },
    {
      path: '/loginReg',
      name: 'loginReg',
      component: PCNewHome2
    },
    {
      path: '/login',
      name: 'loginReg',
      component: PCNewHome2
    },
    {
      path: '/discoverProject',
      name: 'discoverProject',
      component: PCNewHome2
    },
    {
      path: '/fan_activities',
      name: 'promo_list_2',
      component: MobilePromo2
    },
    {
      path: '/fan_activities/:id',
      name: 'project_detail_pc',
      component: PCPromoDetail
    },
    {
      path: '/s/:id',
      name: 'spage_pc',
      component: PCSpage
    },
    {
      path: '/leaderboard/:id',
      name: 'leaderboard',
      component: PCTip
    },
    {
      path: '/pixel_war/:id',
      name: 'pixel_war',
      component: PCTip
    },
    {
      path: '/user_center',
      name: 'user_center',
      component: PCTip
    }
  ]
}else if(a == 'mobile') {
  routes = [
    // {
    //   path: '/',
    //   name: 'MobileHome',
    //   component: MobileHome
    // },
    {
      path: '/projectList',
      name: 'projectList',
      component: MobileProjectList
    },
    // {
    //   path: '/project',
    //   name: 'project',
    //   component: MobileProject
    // },
    {
      path: '/easyRegistration',
      name: 'EasyRegistration',
      component: MobileRegistration
    },
    {
      path: '/inner_login',
      name: 'login',
      component: MobileLogin
    },
    {
      path: '/login',
      name: 'mobileLogin',
      component: MobileLoginNew
    },
    {
      path: '/mobileFirstChoice',
      name: 'mobileFirstChoice',
      component: MobileFirstChoice
    },
    {
      path: '/investmentMessage',
      name: 'investmentMessage',
      component: MobileInvestment
    },
    {
      path: '/wechatAuth',
      name: 'wechatAuth',
      component: MobileWechatAuth
    },
    {
      path: '/wechatBind',
      name: 'wechatBind',
      component: MobileWechatBind
    },
    {
      path: '/projectSummary',
      name: 'projectSummary',
      component: MobileProjectSummary
    },
    // {
    //   path: '/',
    //   name: 'projectSummary',
    //   component: MobileProjectSummary
    // },
    // {
    //   path: '/project',
    //   name: 'project',
    //   component: MobileProjectB
    // },
    {
      path: '/store',
      name: 'store',
      component: MobileStore
    },
    {
      path: '/hornActivity',
      name: 'hornActivity',
      component: MobileHornActivity
    },
    // {
    //   path: '/projects/:projectId',
    //   name: 'project',
    //   component: MobileProjectHunt
    // },
    {
      path: '/slinks/:id',
      name: 'project_vote',
      component: MobileProjectVote
    },
    {
      path: '/desktop',
      name: 'desktop',
      component: MobileDesktop
    },
    {
      path: '/teds',
      name: 'daily_ted',
      component: MobileTed
    },
    {
      path: '/teds/:id',
      name: 'ted_lesson',
      component: MobileTedLesson
    },
    {
      path: '/vocabulary_book',
      name: 'vocabulary_book',
      component: MobileTedVocabulary
    },
    {
      path: '/vocabulary_collect',
      name: 'vocabulary_collect',
      component: MobileTedVocabularyCollect
    },
    {
      path: '/unlock_ted',
      name: 'unlock_ted',
      component: MobileUnlockTed
    },
    {
      path: '/android_selecteds',
      name: 'daily_android',
      component: MobileAndroid
    },
    {
      path: '/promo_list',
      name: 'promo_list',
      component: MobilePromo
    },
    {
      path: '/promo_detail',
      name: 'promo_detail',
      component: MobilePromoDetail
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: WPDeveloperDashboard
    },
    {
      path: '/d/login',
      name: 'login',
      component: WPLogin
    },
    {
      path: '/d/promos/:id',
      name: 'promos',
      component: WPPromos
    },
    {
      path: '/d/promo_form',
      name: 'promo_form',
      component: WPCreate
    },
    {
      path: '/fan_activities',
      name: 'promo_list_2',
      component: MobilePromo2
    },
    {
      path: '/leaderboard/:id',
      name: 'leaderboard',
      component: MobileLeaderboard
    },
    {
      path: '/fan_activities/:id',
      name: 'project_detail',
      component: MobileProjectDetail
    },
    {
      path: '/s/:id',
      name: 'spage',
      component: MobileSpage
    },
    {
      path: '/event_enroller',
      name: 'event_enroller',
      component: MobileEventEnroller
    },
    {
      path: '/home_v2',
      name: 'global_selection',
      component: MobileGlobalSelection
    },
    // {
    //   path: '/projects/:projectId',
    //   name: 'project_global',
    //   component: MobileProjectGlobal
    // },
    {
      path: '/projects/:projectId',
      name: 'project_global_2',
      // component: MobileProjectGlobal2
      component: MobileSelectedProject
    },
    {
      path: '/collection',
      name: 'collect_message',
      component: MobileCollectMessage
    },
    {
      path: '/link',
      name: 'link_phone',
      component: MobileLinkPhone
    },
    {
      path: '/pixel_war/:id',
      name: 'pixel_war',
      component: MobilePixelPainter
    },
    {
      path: '/flow/:projectId',
      name: 'flow',
      component: MobileCardFlow
    },
    {
      path: '/user_center',
      name: 'user_center',
      component: MobileUserCenter
    },
    {
      path: '/membership_invite_code',
      name: 'membership_invite_code',
      component: MobileMembershipInviteCode
    }
  ]
}

// PC 和 mobile 端共用的路由
routes = routes.concat([
  {
    path: '/d/project_form',
    name: 'project_form',
    component: ProjectFrom
  },
  {
    path: '/d/price_promo_form',
    name: 'price_promo_form',
    component: PricePromoFrom
  },
  {
    path: '/d/apply_form',
    name: 'apply_fan_activity_form',
    component: ApplyFanActivityForm
  },
  {
    path: '/d/promo_center',
    name: 'promo_center',
    component: PromoCenter
  },
  {
    path: '/d/form_submitted',
    name: 'promo_form_submitted',
    component: PromoFormSubmitted 
  },
])
export default new Router({
  routes,
  mode: 'history',
  scrollBehavior(to, from,savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})
