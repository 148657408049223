<template>
  <div id="app">
    <!-- <img src="./assets/logo.png"> -->
    <router-view/>
  </div>
</template>

<script>
import config from '../config'
import Utils from './js/utils.js'
import Analytics from './js/analytics.js'

export default {
  name: 'App',
  methods: {
    
  },
  mounted () {
    Analytics.prepareAnlayze()
    Utils.getTimeParams()
    Utils.redirectUrl(this)
    Utils.updateCardFlowMark()
  },
  watch: {
    '$route' (to, from) {
      var referer = from && from.fullPath
      Analytics.recordVisit(referer)
    }
  }
}
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #000;
}
</style>
