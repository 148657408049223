import storage from "../js/storage"

let Common = {
	randomStr(n) {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

		for (var i = 0; i < n; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));

		return text;
	},
	isWechat() {
		var ua = window.navigator.userAgent.toLowerCase()
		return ua.match(/MicroMessenger/i) == 'micromessenger'
	},
	isFollow() {
		var ua = window.navigator.userAgent.toLowerCase();
		if (ua.includes('follow')) {
			return true;
		}
		return storage.get_s('sMode') == 'follow';
	},
	isMobile() {
		if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
			return true;
		}
		if (Common.isFollow() || Common.isWechat()) {
			return true;
		}
		return false;
	},
	isLogin() {
		return !!storage.get('userInfo')
	},
	logout() {
		storage.remove('userInfo');
		storage.remove('access_token');
		storage.remove('refresh_token');
		storage.remove('ted_vip');
    storage.remove('wechatBind');
	}
}
export default Common;